import React, {
    useEffect,
    useState
} from "react";

import {
    Link,
    useLocation
} from 'react-router-dom';

import Constants from '../../Constants';
import { LocalDB } from "../../helpers/localDB";
import { hideSideMenuIfVisible } from '../../helpers/viewHelper';

function MenuItemPageLink({children, to, className}) {
    const onMenuItemClicked = (e) => {
        hideSideMenuIfVisible();
    };

    return (
        <Link to={to} className={className} onClick={onMenuItemClicked}>
            {children}
        </Link>
    );
}

function SideNavBar() {
    const location = useLocation();

    const [ user, setUser ] = useState(null);

    const ordersMenuItems = () => {
        return (
            <li className="nav-item menu-open">
                <a href="javascript:void(0);" className="nav-link">
                    <i className="nav-icon fas fa-truck"></i>
                    <p>
                        Orders
                        <i className="right fas fa-angle-left"></i>
                    </p>
                </a>
                <ul className="nav nav-treeview">
                    {
                        user && user.suluva_pos_user_role == 'admin' &&
                        <li className="nav-item">
                            <MenuItemPageLink to="/orders/sale-orders" className={"nav-link" + (location.pathname == '/orders/sale-orders' ? ' active' : '')}>
                                <i className="fas fa-hand-holding-dollar nav-icon"></i>
                                <p>Sale Orders</p>
                            </MenuItemPageLink>
                        </li>
                    }
                    {
                        user && (user.suluva_pos_user_role == 'admin' || (user.suluva_pos_user_role == 'user' && ['kitchen', 'outlet'].includes(user.user_location_type))) &&
                        <li className="nav-item">
                            <MenuItemPageLink to="/orders/manufacture-orders" className={"nav-link" + (location.pathname == '/admin/manufacture-orders' ? ' active' : '')}>
                                <i className="fas fa-gears nav-icon"></i>
                                <p>Manufacture Orders</p>
                            </MenuItemPageLink>
                        </li>
                    }
                    {
                        user && (user.suluva_pos_user_role == 'admin' || (user.suluva_pos_user_role == 'user' && ['store', 'kitchen'].includes(user.user_location_type))) &&
                        <li className="nav-item">
                            <MenuItemPageLink to="/orders/purchase-orders" className={"nav-link" + (location.pathname == '/orders/purchase-orders' ? ' active' : '')}>
                                <i className="fas fa-receipt nav-icon"></i>
                                <p>Purchase Orders</p>
                            </MenuItemPageLink>
                        </li>
                    }
                </ul>
            </li>
        );
    };

    const orderHistoryMenuItems = () => {
        return (
            <li className="nav-item menu-open">
                <a href="javascript:void(0);" className="nav-link">
                    <i className="nav-icon fas fa-history"></i>
                    <p>
                        History
                        <i className="right fas fa-angle-left"></i>
                    </p>
                </a>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                        <MenuItemPageLink to="/history/orders" className={"nav-link" + (location.pathname == '/history/orders' ? ' active' : '')}>
                            <i className="fas fa-chart-line nav-icon"></i>
                            <p>Orders</p>
                        </MenuItemPageLink>
                    </li>

                    <li className="nav-item">
                        <MenuItemPageLink to="/history/product-stock" className={"nav-link" + (location.pathname == '/history/product-stock' ? ' active' : '')}>
                            <i className="fas fa-boxes-stacked nav-icon"></i>
                            <p>Stock</p>
                        </MenuItemPageLink>
                    </li>
                </ul>
            </li>
        );
    };

    useEffect(() => {
        setUser({...LocalDB.getJSON('user')});
    }, []);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="javascript:void(0);" className="brand-link">
                <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt={Constants.APP_NAME + ' Logo'} className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                <span className="brand-text font-weight-light">{Constants.APP_NAME}</span>
            </a>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src={process.env.PUBLIC_URL + '/assets/img/icons/user.png'} alt="Profile Logo" className="img-circle elevation-2" style={{ opacity: '.8' }} />
                    </div>
                    <div className="info">
                        <a href="javascript:void(0);" className="d-block">
                            {
                                user && user.name ? <>
                                    {user.name}
                                    {
                                        user.suluva_pos_user_role == 'user' && user.location &&
                                        <>
                                            <br/>
                                            <span className="d-inline-flex align-items-center" style={{ fontSize: '0.8em', opacity: 0.7 }}>
                                                <i className={
                                                    "fas " + (
                                                        user.user_location_type == 'warehouse' ? 'fa-warehouse' : (
                                                            user.user_location_type == 'kitchen' ? 'fa-fire-burner' : (
                                                                user.user_location_type == 'outlet' ? 'fa-store' : 'fa-shop'
                                                            )
                                                        )
                                                    )
                                                } style={{ fontSize: '0.8em' }}></i>&nbsp;{user.location.name}
                                            </span>
                                        </>
                                    }
                                </> : 'User'
                            }
                        </a>
                    </div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {
                            user && user.suluva_pos_user_role && user.suluva_pos_user_role == 'admin' &&
                            <>
                                <li className="nav-item">
                                    <MenuItemPageLink to="/" className={"nav-link" + (location.pathname == '/' ? ' active' : '')}>
                                        <i className="nav-icon fas fa-chart-pie"></i>
                                        <p>Dashboard</p>
                                    </MenuItemPageLink>
                                </li>
                                <li className="nav-item">
                                    <MenuItemPageLink to="/admin/users" className={"nav-link" + (location.pathname == '/admin/users' ? ' active' : '')}>
                                        <i className="nav-icon fas fa-users"></i>
                                        <p>Users</p>
                                    </MenuItemPageLink>
                                </li>
                                <li className="nav-item">
                                    <MenuItemPageLink to="/admin/locations" className={"nav-link" + (location.pathname == '/admin/locations' ? ' active' : '')}>
                                        <i className="nav-icon fas fa-map-location-dot"></i>
                                        <p>Locations</p>
                                    </MenuItemPageLink>
                                </li>
                                <li className="nav-item">
                                    <MenuItemPageLink to="/admin/products" className={"nav-link" + (location.pathname == '/admin/products' ? ' active' : '')}>
                                        <i className="nav-icon fas fa-cubes"></i>
                                        <p>Products</p>
                                    </MenuItemPageLink>
                                </li>
                                <li className="nav-item">
                                    <MenuItemPageLink to="/admin/vendors" className={"nav-link" + (location.pathname == '/admin/vendors' ? ' active' : '')}>
                                        <i className="nav-icon fa fa-shop"></i>
                                        <p>Vendors</p>
                                    </MenuItemPageLink>
                                </li>
                                <li className="nav-item">
                                    <MenuItemPageLink to="/admin/customers" className={"nav-link" + (location.pathname == '/admin/customers' ? ' active' : '')}>
                                        <i className="nav-icon fas fa-users"></i>
                                        <p>Customers</p>
                                    </MenuItemPageLink>
                                </li>
                                { ordersMenuItems() }
                                { orderHistoryMenuItems() }
                            </>
                        }

                        {
                            user && user.suluva_pos_user_role == 'user' && user.user_location_type == 'outlet' &&
                            <>
                                <li className="nav-item">
                                    <MenuItemPageLink to="/counter-user/bill" className={"nav-link" + (location.pathname == '/counter-user/bill' ? ' active' : '')}>
                                        <i className="nav-icon fas fa-money-bills"></i>
                                        <p>Bill</p>
                                    </MenuItemPageLink>
                                </li>
                            </>
                        }

                        {
                            user && user.suluva_pos_user_role == 'user' && ['store', 'kitchen', 'outlet'].includes(user.user_location_type) &&
                            <>
                                <li className="nav-item">
                                    <MenuItemPageLink to="/products/stock" className={"nav-link" + (location.pathname == '/products/stock' ? ' active' : '')}>
                                        <i className="nav-icon fas fa-cart-flatbed"></i>
                                        <p>Transfer Stock</p>
                                    </MenuItemPageLink>
                                </li>
                                { ordersMenuItems() }
                                { orderHistoryMenuItems() }
                            </>
                        }
                    </ul>
                </nav>
            </div>
        </aside>
    );
}

export default SideNavBar;
