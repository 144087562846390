import {
    useEffect,
    useRef,
    useState
} from 'react';

import { Outlet } from 'react-router-dom';

import TopNavbar from '../../widgets/navbar/TopNavbar';
import SideNavbar from '../../widgets/navbar/SideNavbar';
import Constants from '../../Constants';
import { PagePreLoader } from '../../widgets/PreLoader';

import { setWindowTitle, togglePreloader, hideSideMenuIfVisible } from "../../helpers/viewHelper";
import { LocalDB } from '../../helpers/localDB';

function AuthenticatedPageLayout() {
    const currYear = new Date().getFullYear();

    const [ user, setUser ] = useState(null);

    const [ pageTitle, setPageTitle ] = useState('');
    const [ isInlinePageTitleVisible, toggleInlinePageTitle ] = useState(false);
    const [ isFullHeight, forceFullHeight ] = useState(false);
    
    const [ extraIcons, setExtraIcons ] = useState([]);

    const wrapperElemRef = useRef(null);

    useEffect(() => {
        if (document.body.classList.contains('login-page')) {
            document.body.classList.remove('login-page');
        }

        togglePreloader('hide');

        setUser({...LocalDB.getJSON('user')});

        if (wrapperElemRef.current) {
            wrapperElemRef.current.addEventListener('click', hideSideMenu, false);
        }

        return () => {
            if (wrapperElemRef.current) {
                wrapperElemRef.current.removeEventListener('click', hideSideMenu, false);
            }
        };
    }, []);

    useEffect(() => {
        setWindowTitle(pageTitle);
    }, [pageTitle]);

    const addClassToBodyElement = (className) => {
        document.body.classList.add(className);
    };

    const removeClassToBodyElement = (className) => {
        document.body.classList.remove(className);
    };

    const isLocationUser = () => {
        return user && user.suluva_pos_user_role === 'user' && user.location;
    };

    const hideSideMenu = (e) => {
        hideSideMenuIfVisible(e.target);
    };

    return (
        <div className="wrapper" ref={wrapperElemRef}>
            <PagePreLoader />

            {
                TopNavbar({
                    pageTitle,
                    extraIcons
                })
            }
            {SideNavbar()}

            <div className={"content-wrapper " + (isFullHeight ? 'fill-height' : '')}>
                <section className="content-header">
                    <div className="container-fluid">
                        {
                            isInlinePageTitleVisible && <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>{pageTitle}</h1>
                                </div>
                                <div className="col-sm-6"></div>
                            </div>
                        }
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <Outlet context={{
                            user,
                            pageTitle,
                            setPageTitle,
                            addClassToBodyElement,
                            removeClassToBodyElement,
                            setExtraIcons,
                            isLocationUser,
                        }} />
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>Copyright &copy; {currYear} Proflujo Technology.</strong> All rights reserved.
            </footer>

            <div id="sidebar-overlay"></div>
        </div>
    );
}

export default AuthenticatedPageLayout;
